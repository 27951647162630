import React from 'react';

class About extends React.Component {
	render() {
		return (
		<div className = "about">
			<h1> Hi, I'm Alexi Sanchez </h1>
			<h2>A little something about me....</h2>
			<h3> I'm a person who likes sports and working out, I really enjoy learning to program websites.
			I have an Associates in Engineering from Prince George's Community College, but decided I enjoyed programming websites more than what I was doing a the time.
			I've taken courses form udemy and freecodecamp in order to improve my skills
			</h3>
		</div>
			)
	}
}
export default About;