import React from 'react';

class Contact extends React.Component {
	render() {
		return (
<div>
			<h1> Contact info inside Resume now</h1>
			<h2>Email: Removed due to a tip</h2>
			<h2> Cell Phone: Removed please check Resume </h2>

</div>
			)
	}
}
export default Contact;